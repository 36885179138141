/** @jsx jsx */
import { jsx } from "theme-ui"
import { Flex, Box, Text, Link } from "rebass"

const Header = () => {
  return (
    <Box as={"footer"} width={[1, 0.9, 0.8]} mx="auto" p="3" mt={[5, 6]}>
      <Flex my="2">
        <Text textAlign="center" fontWeight={600} fontSize={[1, 2]}>
          Email Us{" "}
          <Link
            variant="nav"
            href="mailto:hellow@dose.app"
            sx={{ textDecoration: "underline" }}
          >
            hello@dose.app
          </Link>
        </Text>
        <Box mx="auto" />
        <Link
          variant="nav"
          href="https://thedoseapp.typeform.com/to/zFRQ2M"
          target="_blank"
        >
          For Trainers
        </Link>
        <Link
          variant="nav"
          href="https://thedoseapp.typeform.com/to/b6QPZo"
          target="_blank"
          ml={[2, 5]}
        >
          For Facilities
        </Link>
      </Flex>
      <Text textAlign="center" fontSize={[0, 2]}>
        © {new Date().getFullYear()} Dose Ventures, Inc.
      </Text>
    </Box>
  )
}

export default Header
