/** @jsx jsx */
import { jsx } from "theme-ui"
import { Link } from "gatsby"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { Flex, Box, Link as A } from "rebass"

const Header = () => {
  const data = useStaticQuery(graphql`
    query {
      logo: file(relativePath: { eq: "dose.png" }) {
        childImageSharp {
          fluid(maxWidth: 80) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Box width={[1, 0.9, 0.8]} mx="auto">
      <Flex as={"header"} px="3" height={60} alignItems="center">
        <Box width={80}>
          <Link to="/">
            <Img fluid={data.logo.childImageSharp.fluid} />
          </Link>
        </Box>
        <Box mx="auto" />
        <Box display={["none", "block"]}>
          <A
            variant="nav"
            href="https://thedoseapp.typeform.com/to/zFRQ2M"
            target="_blank"
          >
            For Trainers
          </A>
          <A
            variant="nav"
            href="https://thedoseapp.typeform.com/to/b6QPZo"
            target="_blank"
            ml={[3, 5]}
          >
            For Facilities
          </A>
        </Box>
      </Flex>
    </Box>
  )
}

export default Header
